<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Jalousien / Rollos <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Germania - Rollos
      </h2>
      <ul class="grid grid-cols-2">
        <li class="col-span-full row-span-4 lg:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </li>

        <li class="col-span-full lg:col-span-1">
          <h2 class="text-2xl p-5 md:pl-10 md:pb-3" style="color: #033859">
            Geben Sie Ihrem Stil eine individuelle Note
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Licht ist Leben und in Farbe und Intensität nirgendwo gleich. Kein
            Raum ist wie der andere. Unterschiedliche Fensterformen und -größen,
            die Himmelsrichtung, das Wetter, die Jahreszeiten – Alles hat einen
            Einfluss auf die Lichtverhältnisse. Sonnen-schutz verfolgt nicht nur
            einen praktischen Aspekt, sondern ist darüber hinaus eine
            Einrichtungskomponente, mit der Sie Ihr Zuhause ganz nach Ihrem
            Geschmack gestalten. Die Rollo-Kollektion mit mehr als 300 Stoffen
            in drei Transparenzarten lässt Spielraum für jede Menge Kreativität.
            <br /><br />
            Unser umfangreiches und stilorientiertes Sortiment macht Luxaflex®
            Rollos so auf-fallend anders und besonders.
          </p>
        </li>
        <li class="col-span-full lg:col-span-1">
          <h2 class="text-2xl p-5 md:pl-10 md:pb-3" style="color: #033859">
            Stilorientierte und einfache Lösungen
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Die einfache Struktur der Rollo-Oberfläche bietet die perfekte
            Grundlage für eine individuelle Komposition am Fenster. Von
            kräftigen Mustern bis hin zu schimmernden Stoffen, von transparent
            bis abdunkelnd, mit Ihrem Rollo schaffen Sie eine persönliche
            Atmosphäre. Egal für welches Design Sie sich entscheiden, Sie
            beeinflussen mit Luxaflex® Rollos auf dekorative Art und Weise den
            Sichtschutz und den Lichteinfall in Ihren Räumen.
          </p>
        </li>
        <li class="col-span-full lg:col-span-1">
          <h2 class="text-2xl p-5 md:pl-10 md:pb-3" style="color: #033859">
            Der letzte Feinschliff
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Dekorative Zierleisten bieten die Möglichkeit, ein Rollo um ein
            individuelles Detail zu er-weitern. Die flachen Zierleisten sind in
            verschiedenen, eleganten Ausführungen erhältlich. Sie lassen sich
            mit den meisten Stoffen des Sortiments kombinieren und bilden einen
            schönen Materialkontrast. Ihr Rollo wird damit zu einem besonderen
            Blickfang am Fenster. Wählen Sie zwischen verschiedenen edlen
            Hölzern und glänzenden oder gebürsteten Metall-Oberflächen. Ein
            eleganter und unvergleichlicher Abschluss für Ihr Luxaflex® Rollo.
          </p>
        </li>
      </ul>
      <div class="p-4">
        <h3 class="text-4xl pt-10 pb-3" style="color: #033859">Eckdaten</h3>
        <tabs :mode="mode">
          <tab title="Powerview">
            <p class="text-lg">
              <span class="font-bold">Motorisierung</span> <br />
              Was wäre, wenn Ihr Sonnenschutz Gedanken lesen könnte? Wenn er
              wüsste, wie Ihr Tag aussehen wird, und wenn er in der Lage wäre,
              ihn noch schöner werden zu lassen ? Dafür gibt es die PowerView®
              Motorisierung – ein kabelloses Motorisierungssystem, mit dem Sie
              alle Sonnenschutz-Produkte per Smartphone, Tablet oder
              Fernsteuerung bedienen können. Die Einbindung in ein Smart
              Home-System ist kein Problem.
            </p>
          </tab>
          <tab title="Kindersicherheit">
            <p class="text-lg">
              <span class="font-bold">Entwickelt mit Blick auf Sicherheit</span>
              <br />
              Kindersicherheit hat oberste Priorität. Von schnurlosen Systemen
              bis hin zu voll motorisierten Alternativen bietet Luxaflex® viele
              innovative Bediensysteme, die auf Sicherheit, Komfort und
              Schönheit ausgelegt sind. Besuchen Sie Ihren regionalen Luxaflex®-
              Fachhändler, um sich über die bestmögliche Bedienung für Ihre
              Einbau-situation zu informieren.
            </p>
          </tab>
          <tab title="Extras">
            <p class="text-lg">
              <span class="font-bold">Extras</span> <br />
              Germania - Rollo ist in verschiedenen Farben und Ausführungen
              erhältich. <br />
              Besuchen Sie unsere Ausstellung oder lassen Sie sich beraten.
            </p>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/germania/rollo/rollo-4.webp"),
          alt: "germania rollo blau",
        },
        {
          image: require("@/assets/img/germania/rollo/rollo-6.webp"),
          alt: "rollo weiß in wohnzimmer",
        },
        {
          image: require("@/assets/img/germania/rollo/rollo-8.webp"),
          alt: "rollo pink",
        },
        {
          image: require("@/assets/img/germania/rollo/rollo-13.webp"),
          alt: "rollo blumenmuster wohnzimmer",
        },
        {
          image: require("@/assets/img/germania/rollo/rollo-18.webp"),
          alt: "rollo mit klassischem design",
        },
        {
          image: require("@/assets/img/germania/rollo/rollo-20.webp"),
          alt: "rollo weiss",
        },
      ],
    };
  },
};
</script>
